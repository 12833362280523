// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$img-path: '/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images';
$download-image: url('#{$img-path}/Icons/download-main.svg');
$download-image-primary: url('#{$img-path}/Icons/download-primary.svg');
$download-image-secondary: url('#{$img-path}/Icons/download-secondary.svg');

$white: #fff;
$gray-100: #ffffff;
$gray-300: #949490;
$gray-800: #4D4F4D;
$gray-a2: #A2A2A2;
$gray-26: #262626;
$gray-f6: #F6F6F6;
$black: #333333;

$blue: #01257D;
$light-blue: #658BE6;

// Color system

$primary: $blue;
$secondary: $light-blue;
$third: $light-blue;
$light: $gray-f6;
$dark: $gray-800;

$shadow: rgba(0, 0, 0, 0.16);

$link-color: $black;
$link-hover-color: $light-blue;
$link-alternate-color: $white;
$link-alternate-hover-color: $white;

$swatch-colors: (
        primary: (
            background: $primary,
            color: $dark
        ),
        light: (
            background: $light,
            color: $dark
        ),
        light-300: (
            background: $gray-300,
            color: $dark
        )
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 10px;

$spacers: (
        0: 0,
        1: ($spacer * 0.25),
        2: ($spacer * 0.5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 2),
        6: ($spacer * 2.5)
);

$sizes: (
        50: 50%,
        100: 100%,
        auto: auto
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-800;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        xl:1362px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 20px;
$grid-gutter-width-lg: 20px;


// Components
$border-radius: 0;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Red Hat Display", sans-serif;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem;
$font-size-lg: 1.063rem; //17px
$font-size-sm: 0.938rem; //15px

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 500;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.467;
$line-height-base-xl: 1.56;

$headings-line-height: 1;

$h1-font-size: 2.375rem; //38px
$h1-font-size-xl: 3.75rem; //60px
$h2-font-size: 1.875rem; //30px
$h2-font-size-xl: 2.5rem; //40px
$h3-font-size: 1.563rem; //25px
$h3-font-size-xl: 2.125rem; //34px
$h4-font-size: 1.25rem; //20px
$h4-font-size-xl: 1.438rem; //23px

$font-size-mobile-nav: 1.125rem; //18px
$font-size-mobile-nav-second: 0.875rem; //14px
$font-size-nav: 1.125rem; //18px

$display1-size: 2.8125rem; //45px
$display1-size-xl: 6.25rem; //100px
$display2-size: 2.5rem; //40px
$display2-size-xl: 4.6875rem; //75px
$display3-size: 1.875rem; //30px
$display3-size-xl: 3.125rem; //50px
$display4-size: 1.25rem; //20px
$display4-size-xl: 1.875rem; //30px

$display-font-family: "DM Serif Display", serif;

$lead-font-size: 1.625rem; //26px
$lead-font-size-xl: 1.75rem; //28px

$button-font-family: $display-font-family;

$headings-font-family:        "DM Serif Display", sans-serif;
$headings-font-weight:        $font-weight-normal;
$headings-color:              $primary;

// Transitions
$transition-time: 0.3s;
$transition-ease: ease-in-out;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 0;
$btn-padding-x: 0;
//$btn-font-family:             $input-btn-font-family !default;
$btn-font-size: 0.875rem;
$btn-font-size-xl: 0.938rem;
$btn-secondary-font-size: 0.875rem;
$btn-secondary-font-size-xl: 1rem;
$btn-secondary-padding-y: 0.5rem;
$btn-secondary-padding-x: 1.375rem;
//$btn-line-height:             $input-btn-line-height !default;
$btn-white-space: pre-wrap;
$btn-transition: all $transition-time $transition-ease;

//$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
//$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

//$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Navbar

$navbar-padding-y:                  $spacer / 2 !default;
$navbar-padding-x:                  $spacer !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
$navbar-font-size: 1.125rem; //18px
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
//$navbar-toggler-border-radius:      $btn-border-radius !default;

$navbar-nav-scroll-max-height:      75vh !default;

$navbar-mobile-bg-color-first-level: $gray-f6;
$navbar-mobile-line-color: #A5A5AA;

//$navbar-dark-color:                 rgba($white, .5) !default;
//$navbar-dark-hover-color:           rgba($white, .75) !default;
//$navbar-dark-active-color:          $white !default;
//$navbar-dark-disabled-color:        rgba($white, .25) !default;
//$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
//$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
//
//$navbar-light-color:                rgba($black, .5) !default;
//$navbar-light-hover-color:          rgba($black, .7) !default;
//$navbar-light-active-color:         rgba($black, .9) !default;
//$navbar-light-disabled-color:       rgba($black, .3) !default;
//$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
//$navbar-light-toggler-border-color: rgba($black, .1) !default;
//
//$navbar-light-brand-color:                $navbar-light-active-color !default;
//$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
//$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem !default;
$dropdown-padding-x:                0 !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size: 1.125rem; //14px
$dropdown-font-size-xl: 1.125rem; //18px
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius !default;
//$dropdown-border-width:             $border-width !default;
//$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
//$dropdown-divider-bg:               $gray-200 !default;
//$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color:               $black !default;
//$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
//$dropdown-link-hover-bg:            $gray-200 !default;

//$dropdown-link-active-color:        $component-active-color !default;
//$dropdown-link-active-bg:           $component-active-bg !default;

//$dropdown-link-disabled-color:      $gray-500 !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;

//$dropdown-header-color:             $gray-600 !default;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;



//
// Frame Inner Spacing
//
$frame-inner-spacing: 1rem;
$frame-inner-spacing-scaling: (
        xs: 0.725,
        sm: 0.875,
        md: 1.125,
        lg: 1.225,
        xl: 1.395
);

$frame-inner-spacing-no-header-image: (
        xs: 1rem,
        sm: 1rem,
        md: 1rem,
        lg: 3.24125rem,
        xl: 3.24125rem
);

$background-stripe: url('#{$img-path}/Icons/stripe-pattern.svg');

//
// Frame Background
//
$frame-backgrounds: (
    blue: (
        background-color: $secondary,
        color: $white,
        headings-color: $white,
        link-color: $white,
        link-hover-color: $primary
    ),
    light: (
        background-color: $light,
        color: $gray-26,
        link-color: $gray-26,
        link-hover-color: $secondary
    )
);



//
// Cards
//

$card-title-size: 2.125rem;
$card-subtitle-size: 0.8rem;

$card-title-font-color: $headings-color;
$card-title-font-family: $headings-font-family;
$card-title-font-weight: $headings-font-weight;

$card-deck-margin: 10px;


//
// Accordeon
//

$accordeon-bg: $gray-f6;

// Utilities

$displays: none, inline-block, block, flex;
$overflows: auto, hidden !default;
$positions: relative, absolute, fixed;

// Teaser
$teaser-font-size: 1.875rem;
$teaser-font-size-xl: 1.875rem;

// langswitcher

$lang-background-color: $gray-f6;
$lang-link-color-current: $black;
$lang-link-color-default: $gray-a2;
$lang-font-size-mobile: 0.813rem;
$lang-font-size: 1.125rem;

// Forms
$label-margin-bottom: 0;

$input-padding-top: 1.375rem;
$input-padding-bottom: 1.25rem;
$input-padding-top-lg: 1.5rem;
$input-padding-bottom-lg: 1.375rem;
$input-padding-x: 0.75rem;
$input-padding-x-lg: 1rem;
$input-font-family: $font-family-base;
$input-font-size: $font-size-sm;
$input-line-height: 1.13158;

$input-bg: rgba($gray-100, 0.5);
$input-disabled-bg: $gray-300;

$input-color: $dark;
$input-border-color: transparent;
$input-border-width: 0;
$input-box-shadow: none;

$input-border-radius: $border-radius;

$input-focus-bg: rgba($gray-300, 0.5);
$input-focus-border-color: transparent;
$input-focus-color: $dark;

$input-placeholder-color: $gray-800;
$input-plaintext-color: $body-color;

$input-height-border: 0;

$input-transition: background-color .15s ease-in-out;

$form-grid-gutter-width: 16px;
$form-grid-gutter-width-xs: 2px;
$form-group-margin-bottom: 1rem;

$custom-control-gutter: 1rem;
$custom-control-spacer-x: 0.125rem;
$custom-control-cursor: pointer;

$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-bg: rgba($dark, 0.08);
$custom-control-hover-indicator-bg: rgba($dark, 0.12);

$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-disabled-bg: rgba($primary, .5);

$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: lighten($primary, 25%);

$custom-checkbox-indicator-border-radius: $border-radius;

$custom-checkbox-indicator-indeterminate-bg: $primary;
$custom-checkbox-indicator-indeterminate-color: $white;

$custom-radio-indicator-border-radius: 50%;

$custom-control-block-padding: 0.75rem;

// Form validation

$form-feedback-valid-color: $primary;
$form-feedback-invalid-color: red;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'><path fill='#{$form-feedback-icon-valid-color}' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'></path></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'><path fill='#{$form-feedback-icon-invalid-color}' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'></path></svg>");

/* This Setting is for Autoprefixer IE Support CSS Grid
// https://github.com/postcss/autoprefixer#options*/
/* autoprefixer grid: autoplace */
